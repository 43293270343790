<template>
  <div class="mt-1">
    <div>
      <div class="title_resultsPatsnt">
        <div class="title_resultsPatsnt_text">
          {{ $t("message.patient_balance") }}
        </div>
        <div class="align-items-center align-self-center text-right pr-0 mb-2">
          <span class="align-items-center align-self-center pr-2">
            <el-tag
              >{{ $t("message.total") }}:
              {{
                modal_balance ? modal_balance.total_amount : 0 | formatMoney
              }}</el-tag
            >
          </span>
          <span class="align-items-center align-self-center pr-2">
            <el-tag type="success"
              >{{ $t("message.paid") }}:
              {{
                modal_balance ? modal_balance.paid_up : 0 | formatMoney
              }}</el-tag
            >
          </span>
          <span class="align-items-center align-self-center pr-2">
            <el-tag type="warning"
              >{{ $t("message.not_paid") }}:
              {{
                modal_balance ? modal_balance.not_paid : 0 | formatMoney
              }}</el-tag
            >
          </span>
        </div>
      </div>
      <div class="nurseOffice_tabs" v-loading="loadingData">
        <el-tabs type="border-card">
          <el-tab-pane :label="$t('message.all_service')">
            <BalanceUslugaList />
          </el-tab-pane>
          <!-- end  el-tab-pane  -->
          <el-tab-pane :label="$t('message.history_transaction')">
            <BalanceList />
          </el-tab-pane>
          <!-- end  el-tab-pane  -->
        </el-tabs>
      </div>
      <div class="title_resultsPatsnt" style="float: right">
        <el-button @click="dialogVisible = true" type="success">{{
          $t("message.pay")
        }}</el-button>
      </div>
      <el-dialog
        :title="$t('message.pay')"
        :visible.sync="dialogVisible"
        width="50%"
        :append-to-body="true"
      >
        <el-form
          ref="formPay"
          :model="pay_form"
          :rules="rules"
          size="mini"
          @keyup.enter.native="submitTransaction"
        >
          <el-row :gutter="36">
            <el-col :span="6">
              <el-form-item
                :label="columns.payment_type_id.title"
                prop="payment_type_id"
              >
                <el-select
                  v-model="pay_form.payment_type_id"
                  filterable
                  :placeholder="columns.payment_type_id.title"
                >
                  <el-option
                    v-for="item in paymentTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item :label="columns.price.title">
                <el-input
                  v-model="pay_form.amount"
                  :placeholder="columns.price.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item :label="columns.date.title">
                <el-date-picker
                  type="date"
                  v-model="pay_form.date"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                  :placeholder="columns.date.title"
                  :picker-options="datePickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item :label="columns.comment.title" prop="comment">
                <el-input
                  v-model="pay_form.comment"
                  :placeholder="columns.comment.title"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-button
            type="success"
            class="px-3"
            style="margin-left: 40%; padding: 12px !important"
            @click.native.prevent="pay()"
            :loading="loadingButton"
          >
            <!-- <i class="el-icon-refresh-right"></i> -->
            {{ $t("message.pay") }}
          </el-button>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import BalanceUslugaList from "./components/balanceUslugaList";
import BalanceList from "./components/balanceList";
import { mapGetters, mapActions } from "vuex";
import { correctInputNumber } from "@/filters";
export default {
  name: "clientBalance",
  components: {
    BalanceUslugaList,
    BalanceList,
  },
  data() {
    return {
      loadingData: false,
      dialogVisible: false,
      pay_form: {
        date: new Date(),
      },
      loadingButton: false,
      datePickerOptions: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 8.64e7 * 2;
        },
      },
    };
  },
  computed: {
    getId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      paymentTypes: "paymentTypes/list",
      modal_balance: "patientBalances/modal_balance",
      lastHistory: "dailyTreatments/lastHistory",
      rules: "transaction/rules",
      columns: "transaction/columns",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  watch: {
    lastHistory: {
      handler: function (val, oldVal) {
        this.editModel({
          hospital_patient_id: this.getId,
          id: val.id,
        })
          .then((res) => {})
          .catch((err) => {
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
      },
    },
    'pay_form.amount': function(newPrice) {
      this.correctInputNumber('pay_form', 'amount', newPrice);
    },
  },
  async mounted() {
    this.loadingData = true;
    var vm = this;
    this.editModel({
      hospital_patient_id: vm.getId,
      id: this.lastHistory.id,
    })
      .then((res) => {
        this.loadingData = false;
      })
      .catch((err) => {
        this.loadingData = false;
        this.$notify({
          title: "Ошибка",
          type: "error",
          offset: 130,
          message: err.error.message,
        });
      });

    this.paymentTypesList();
  },
  methods: {
    ...mapActions({
      paymentTypesList: "paymentTypes/index",
      indexTransaction: "patientTransactions/indexitems",
      editModel: "patientBalances/showBalance",
      empty: "patientBalances/empty",
      save: "patientTransactions/store",
      refreshData: "patientBalances/refreshData",
      showModels: "hospitalizationConclusions/indexitems",
    }),
    correctInputNumber,
    pay() {
      this.loadingButton = true;
      this.pay_form.patient_history_id = this.lastHistory.id;
      this.pay_form.amount = this.strToInt(this.pay_form.amount);
      this.save(this.pay_form)
        .then((res) => {
          this.loadingButton = false;
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          var vm = this;
          this.editModel({
            hospital_patient_id: vm.getId,
            id: this.lastHistory.id,
          })
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });

          this.editModel({
            hospital_patient_id: vm.getId,
            id: this.lastHistory.id,
          })
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });

          this.dialogVisible = false;
          this.pay_form = { date: new Date() };
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    strToInt(val) {
      if (typeof(val) === 'string') {
        return parseInt(val.replace(/\s/g, ''));
      }
      return val;
    }
  },
};
</script>
